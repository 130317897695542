"use client";

import React, { FC } from "react";
import Button from "@/components/Buttons/Primary/Button";
import { useSession } from "next-auth/react";
import useAppHeight from "@/hooks/useAppHeight";
import { useTranslations } from "next-intl";
import dynamic from "next/dynamic";
const LottieComponent = dynamic(() => import("@/components/Lottie/Lottie"));

const NotFoundPage: FC = () => {
  useAppHeight();
  const { data: session } = useSession();
  const t = useTranslations();

  return (
    <div className="pb-[24px] md:pb-[45px] px-[20px] bg-white min-h-[100vh] flex flex-col justify-center items-center grow gap-[36px]">
      <div className="hidden md:block rounded-[12px] overflow-hidden">
        <LottieComponent
          getAnimationData={() => import("public/lottie/404Desktop.json")}
          id="404Desktop"
        />
      </div>
      <div className="block md:hidden rounded-[12px] overflow-hidden">
        <LottieComponent
          getAnimationData={() => import("public/lottie/404Mobile.json")}
          id="404Mobile"
        />
      </div>
      <p className="text-[32px] leading-[38px] font-bold text-center">
        {t("NotFound.title")}
      </p>
      <p className="text-[18px] leading-[28px] max-w-[550px] text-center">
        {t("NotFound.subtitle")}
      </p>
      <a href="/">
        <Button
          className="w-min"
          label={
            !session || session.provider !== "credentials"
              ? t("NotFound.backToHome")
              : t("NotFound.backToDashboard")
          }
        />
      </a>
    </div>
  );
};

export default NotFoundPage;
