import { twMerge } from "tailwind-merge";
import styles from "./Spinner.module.scss";
import { FunctionComponent } from "react";

type Props = { className?: string; innerClassName?: string };

const Spinner: FunctionComponent<Props> = ({ className, innerClassName }) => {
  return (
    <div className={className}>
      <div className={twMerge(styles.Spinner, innerClassName)} />
    </div>
  );
};

export default Spinner;
